@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables */
:root {
  --main-bg-color: #031f47;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: var(--main-bg-color);
  font-size: 16px;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  min-height: 100vh;

  .body {
    min-height: 100vh;
  }
}
